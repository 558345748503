<template>
  <div>

        <Pane />

    <a-card class="container">
      <Padding />
      <input
              ref="upload"
              style="display: none"
              type="file"
              :multiple="false"
              @change="onFileChange"
            />
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">

            <a-form-model-item>
              <a-input
                placeholder="设计编号"
                v-model="form.designCode"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.contractName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="生产负责人" v-model="form.chargerName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="责任部门"
                show-search
                :filter-option="$selectFilterOption"
                v-model="form.productionUnitId"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.uniqueName
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            
            <!-- <a-form-model-item>
              <a-select style="width: 150px" v-model="form.allocateType" placeholder="确认情况">
                <a-select-option
                  v-for="item in [
                    {
                      name: '当年已全部分配',
                      value: 'progressConfigured',
                    },
                    {
                      name: '当年已确认未分配',
                      value: 'notProgressConfigured',
                    }
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item>
              <a-range-picker
                :mode="['month', 'month']"
                :placeholder="['月份', '月份']"
                v-model="selectedMonthList"
                format="YYYY-MM"
                style="width: 150px"
                @panelChange="monthPanelChange"
                :allowClear="false"
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
            <a-button type="primary" style="float: right;margin-top: 5px;" :loading="uploading" @click="importFile">批量导入收支</a-button>
      </a-form-model>
      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
        rowKey="id"
      >

        <a-table-column title="设计编号" data-index='designCode' align='center' />
        <a-table-column title="合同名称" data-index='name' />
        <a-table-column title="所属区域">
          <template slot-scope="text">{{ text.contract.areaName }}</template>
        </a-table-column>
        <a-table-column title="责任部门/负责人">
          <template slot-scope="text">{{ text.contract.productionUnitName }}-{{ text.chargerName }}</template>
        </a-table-column>
        <a-table-column title="合同金额(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.projectAmount" />
          </template>
        </a-table-column>
        <a-table-column title="分包总额(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.subSum" />
          </template>
        </a-table-column>
        <a-table-column title="合同净额(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.projectPureAmount" />
          </template>
        </a-table-column>
        <a-table-column title="当年已收入(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.incomeSum" />
          </template>
        </a-table-column>
        <a-table-column title="当年已收入未分配(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.incomeNotAllocatedSum" />
          </template>
        </a-table-column>
        <!-- <a-table-column title="未收入(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.unearnedIncome" />
          </template>
        </a-table-column> -->
        <a-table-column align="center" title="操作" width="120px">
          <template slot-scope="text">
            <div>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="$router.push($route.path + '/detail?id=' + text.id)"
              >查看</a>
            </div>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import moment from 'moment'
import organization from "@/mixins/organization";




function fetchList(data) {
  return request({
       url: '/project-service/projectFin/list',
       method: "post",
       data
  });
}

function upload(data) {
  return request({
    url: "/project-service/projectFin/import",
    method: "post",
    data,
  });
}

export default {
  mixins: [ watermark,organization],

  components: {

  },

  data() {
    return {
      historyAnnualData: [],
      historyProfit: 0,
      statistic: [],
      statisticProfit: 0,
      month: moment().format("YYYY-MM"),

      form: {},
      selectedMonthList: [],
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
      uploading: false,
    };
  },

  computed: {
    monthObj() {
        const item = this.statistic.find(item => item.date === this.month);
        console.log('item',item)
        return item ? item : {}
    }
  },

  mounted() {
    this.selectedMonthList = [moment().format("YYYY") + '-' + '01', '']
    this.form.monthStart = moment().format("YYYY") + '-' + '01';
    this.form.monthEnd = '';
    this.getList();
  },

  methods: {
    monthPanelChange(values) {
      if (values.length === 2) {
        this.selectedMonthList = [
          moment(values[0]).format("YYYY-MM"),
          moment(values[1]).format("YYYY-MM")
        ]
        this.form = {
          ...this.form,
          monthStart: moment(values[0]).format("YYYY-MM"),
          monthEnd: moment(values[1]).format("YYYY-MM"),
        };
      }
    },
    importFile() {
      this.$refs.upload.click();
    },
    onFileChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.uploading = true;

      upload(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            if (res.length > 0) {
              alert(res.filter((item, index) => index < 10).join("; "));
            } else {
              this.$message.success("导入成功");
              this.getList();
            }
          }
        })
        .finally(() => {
          this.uploading = false;
          e.target.value = null;
        });
    },
    getList() {
      const { current, pageSize } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },


    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.selectedMonthList = ['', '']
      this.getList();
    },

  }
};
</script>
<style lang="less" scoped>
.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }

    .setting {
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>